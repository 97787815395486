
import Joi from 'joi'
import { Component, Mixins } from 'vue-property-decorator'
import { Step } from '@/mixins/StepMixin'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

@Component({
  components: {
    VueTelInput
  }
})
export default class BusinessInfo extends Mixins(Step) {
  fields: any = {
    phoneNumber: '',
    street: '',
    zip: '',
    city: ''
  }

  errors: any = {
    phoneNumber: '',
    street: '',
    zip: '',
    city: ''
  }

  schema = {
    phoneNumber: Joi
      .string()
      .length(8)
      .pattern(/^[0-9]+$/),
    street: Joi
      .string()
      .min(3),
    zip: Joi
      .string()
      .length(4)
      .pattern(/^[0-9]+$/),
    city: Joi
      .string()
      .min(3)
  }

  telInput = {
    onlyCountries: ['DK'],
    defaultCountry: 'DK'
  }

  mounted (): void {
    this.$store.dispatch('setCurrentPageInvalid')

    const storedBusinessInfo = this.$store.state.userData.businessInfo
    if (storedBusinessInfo.phoneNumber) {
      this.$store.dispatch('setNextStatus', true)
      for (const prop in storedBusinessInfo) {
        this.fields[prop] = storedBusinessInfo[prop]
      }
    }

    this.validateFields()
  }

  get showFormErrors () {
    return this.$store.getters.formErrors
  }

  async validateFields () {
    const result = await this.validate(this.schema, this.fields)

    if (result && result.errors !== undefined) {
      this.errors = {}
      Object.assign(this.errors, result.errors)
      this.errors.phoneNumber = this.errors.phoneNumber ? this.errors.phoneNumber.split('{num}').join('8') : ''
      this.errors.zip = this.errors.zip ? this.errors.zip.split('{num}').join('4') : ''
    } else {
      this.errors = {}
      this.$store.dispatch('setUserData', { businessInfo: this.fields })
      this.$store.dispatch('setCurrentPageValid')
    }
  }

  setFieldData (evt: any): void {
    this.$store.dispatch('setNextStatus', true)
    if (evt) {
      this.fields[evt.target.name] = evt.target.value
    }
    this.validateFields()
  }
}
